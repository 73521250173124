<template>
  <!-- <div class="select">
    <select v-model="$i18n.locale" @change="changeLang">
      <option value="en">English</option>
     <option value="zh">中文</option>  
      <option value="fr">France</option>
      <option value="de">Deutschland</option>
      <option value="it">Italia</option>
      <option value="pt">Portugal</option>
      <option value="es">español</option>
    </select>
  </div> -->
  <div class="bg mask flex-column">
    <div class="logo">
      <img src="/logo.png" alt="" />
    </div>
    <!-- <p class="title">ESA LIANG</p> -->
    <p class="p">{{ $t("welcome") }}</p>
  </div>
</template>

<script>
export default {
  setup () {
    return { locale: "en" };
  },
  methods: {
    async created () { }
  },
  mounted () { }
};
</script>
<style lang="scss" scoped>
.bg {
  background: #9fa0a0;
}
.logo {
  max-width: 500px;
  padding-top: 100px;
  text-align: center;
}
.title {
  max-width: 500px;
  padding: 20px;
  font-size: 40px;
  color: #fff;
  text-align: center;
}
.p {
  max-width: 500px;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
}
.select {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9999;
  color: #fff;
  select {
    border-radius: 40px;
    padding-left: 10px;
    border: none;
    color: #666;
    line-height: 26px;
    height: 26px;
  }
}
</style>
